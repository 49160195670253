import React from 'react';
import Layout from "../components/layout";
import Seo from "../components/seo";

const divParaStyle = {
  marginTop: '50px'
}

const Rahmenbedingungen = () => {
  return (
      <Layout>
        <Seo title="Rahmenbedingung der Naturspielgruppe Wakiti in Uster" />
        <div className="content">
          <div className='conditions'>
              <h1>Rahmenbedingungen</h1>
              <div style={divParaStyle}>
                {/* <h4>Ziel der Spielgruppe</h4> */}
                <p>In der Naturspielgruppe Wakiti treffen sich bis zu zwölf Kinder vor dem Kindergarteneintritt zum freien Spielen in der Natur. Die Spielgruppe wird von einer ausgebildeten Fachfrau Betreuung Kind mit Zusatzausbildung Basis Wald geleitet. Ab sechs Kindern wird die Leiterin von einer Assistentin / einem Assistenten unterstützt. Die Spielgruppenleiterin hat den Nothelferkurs für Kleinkinder absolviert und ist in der Lage, Erste Hilfe zu leisten. Sie hat während der Spielgruppenzeit stets ein Handy sowie Telefonlisten von Eltern und Notfallnummern bei sich.</p>
                <p>Sie bietet frühkindliche Bildung und Betreuung und unterstützt die soziale, emotionale, kognitive, körperliche und psychische Entwicklung von allen Kindern zu eigenverantwortlichen und gemeinschaftsfähigen Persönlichkeiten. Das zentrale Bildungsmittel in diesem Alter ist das Spiel, welches in der Spielgruppe im Mittelpunkt steht. Grundsätzlich darf jedes Kind ab drei Jahren die Spielgruppe besuchen. Je nach Entwicklungsstand des Kindes kann das Alter variieren.</p>
              </div>
              <div style={divParaStyle}>
                <h4>Anmeldung und Probezeit</h4>
                <p>Spielgruppenzeiten:</p>
                <p>Dienstagvormittags von 8.30 -11.30 Uhr</p>
                <p>Bitte melden Sie ihr Kind mit dem Anmeldeformular auf unserer Homepage an, die Aufnahmebestätigung erfolgt per E-Mail. Die Probezeit dauert einen Monat. In dieser Zeit ist es beiden Seiten möglich, innerhalb von sieben Tagen den Vertrag zu kündigen.</p>
                <p>Der Vertrag ist verbindlich, bei einem Vertragsrücktritt vor Beginn des Spielgruppeneintritts verrechnen wir eine Umtriebsentschädigung von CHF 100. Ein Eintritt während des Jahres ist möglich, sofern Platz vorhanden und die Spielgruppenleiterin damit einverstanden ist. Die Gruppendynamik wird dabei beachtet.</p>
              </div>
              <div style={divParaStyle}>
                <h4>Versicherung</h4>
                <p>Die Unfall- und Haftpflichtversicherung des Kindes für den Aufenthalt in der Spielgruppe sowie auf dem Hin- und Rückweg ist Sache der Eltern.</p>
                <p>Die Spielgruppenleiterin ist berufshaftpflichtversichert.</p>
              </div>
              <div style={divParaStyle}>
                <h4>Ferien, Feiertage, Krankheit und sonstige Abwesenheit</h4>
                <p>Falls ihr Kind die Spielgruppe aufgrund von Krankheit, Ferien usw. nicht besuchen kann, bitten wir Sie, dies der Leiterin mitzuteilen. Es dürfen nur gesunde Kinder in die Spielgruppe gebracht werden. Da wir uns im Freien aufhalten, wird sich nur ein gesundes Kind wohl fühlen. Allergien und/oder Krankheiten sind uns schriftlich mitzuteilen. Diese Informationen werden vertraulich behandelt.</p>
                <p>Die Spielgruppenleiterin hat das Recht, kranke Kinder nicht anzunehmen. Erkrankt ein Kind während der Spielgruppenzeit, müssen die Eltern ihr Kind sobald als möglich abholen, sofern sie von der Spielgruppenleiterin kontaktiert wurden.</p>
                <p>Die Kosten für verspasste Spielgruppenzeit werden nicht zurückerstattet und die Stunden können nicht nachgeholt werden.</p>
                <p>Muss die Spielgruppe von der Leiterin abgesagt werden, wird der Beitrag für die ausgefallenen Stunden am Ende des Quartals verrechnet.</p>
                <p>Ferien und Feiertage richten sich nach den Schulferien der Stadt Uster</p>
              </div>
              <div style={divParaStyle}>
                <h4>Kosten und Zahlung</h4>
                <p>Kosten Naturspielgruppe Wakiti:</p>
                <p>1 x 3 Stunden: 36.- CHF</p>
                <p>Das Spielgruppengeld ist halbjährlich zu entrichten und bis spätestens Ende August/Februar zu überweisen. Der Betrag ist unabhängig von der Anwesenheit des Kindes zu bezahlen, da der Spielgruppenplatz für ihr Kind freigehalten wird.</p>
                <p>Muss die Spielgruppe aufgrund behördlicher Anweisung im Falle einer Epidemie oder einer Pandemie geschlossen werden, besteht kein Anspruch auf eine Rückerstattung der Elternbeiträge für die ausgefallenen Stunden.</p>
              </div>
              <div style={divParaStyle}>
                <h4>Mitbringen</h4>
                <p>Da wir uns hauptsächlich im Freien aufhalten, ist wetterentsprechende Kleidung unabdingbar.</p>
                <p>An regnerischen Tagen: Regenkleider, Gummistiefel oder wasserfeste Schuhe, Zwiebelsystem.</p>
                <p>An warmen, sonnigen Tagen: Kopfbedeckung, leichte und geschlossene Schuhe.</p>
                <p>An angekündigten Waldtagen: Lange Kleidung (Zecken), Kopfbedeckung, gute Schuhe.</p>
                <p>Jedes Kind darf einen eigenen Rucksack mitnehmen. Darin sollte sich eine Wasserflasche, Ersatzkleidung und nach Bedarf Windeln befinden. Wir bitten Euch darum, Euren Kindern ausschliesslich Wasser oder ungesüssten Tee mitzugeben.</p>
                <p>Jedes Kind darf natürlich sein liebstes Nuschi/Kuscheltier mitnehmen, zusätzliche Spielsachen bitte zu Hause lassen.</p>
              </div>
              <div style={divParaStyle}>
                <h4>Eingewöhnung</h4>
                <p>Eine sorgfältige Eingewöhnung in der Spielgruppe gibt dem Kind Sicherheit. Dabei lernt es, dass die Eltern fortgehen, aber immer wiederkommen. Wir möchten jedem Kind die Zeit schenken, welche es braucht. Somit gestaltet sich die Eingewöhnung individuell. Einige Kinder fühlen sich schon nach kurzer Zeit wohl, andere brauchen ihre Bezugsperson länger. Wir bitten euch darum, am ersten Spielgruppentag genügend Zeit einzurechnen, damit eure Kinder richtig ankommen können. Sinnvoll wäre es, die Spielgruppe bereits vor dem ersten offiziellen Tag mit euren Kindern zu besuchen. Für Terminvereinbarungen können sie die Spielgruppenleitung gerne kontaktieren.</p>
              </div>
              <div style={divParaStyle}>
                <h4>Kündigungsfrist</h4>
                <p>Der Vertrag läuft automatisch am Ende des Spielgruppenjahres aus. Während des Jahres ist eine schriftliche Kündigung unter Einhaltung einer einmonatigen Kündigungsfreist auf Ende eines Quartals möglich. Der Spielgruppenbeitrag ist bis zum Ablauf der ordentlichen Kündigungsfrist zu bezahlen, auch wenn das Kind die Spielgruppe nicht mehr besucht.</p>
              </div>
          </div>
        </div>
      </Layout>
  )
}

export default Rahmenbedingungen;